<template>
    <el-dialog
        width="600px"
        top="40px"
        :visible.sync="visible"
        :close-on-click-modal="false"
        :before-close="() => !$wait.is('*') && closeModal()"
        @open="getHistory"
        @closed="clearData"
    >
        <span slot="title">
            <div class="absolute w-full top-0 inset-x-0 border-b rounded-t-lg p-4 bg-gray-100">
                <p>
                    {{ $t('clients.history') }}
                </p>
            </div>
        </span>

        <div v-loading="$wait.is('loading_key_history')" class="timeline-container mb-20 mt-12 px-10 flex justify-center" element-loading-spinner="el-custom-spinner">
            <el-timeline>
                <el-timeline-item
                    v-for="entry in historyData"
                    :key="entry.uuid"
                    color="#84c1ff"
                    :timestamp="entry.updated_at"
                >
                    <p class="flex flex-col">
                        {{ currentHolderFormatter(entry.holder) }}
                        <small v-if="entry.causer" class="text-gray-400 text-xs">{{ $t('clients.by') }} {{ entry.causer }}</small>
                    </p>
                </el-timeline-item>
            </el-timeline>

            <NoDataInformation :data="noDataInformation" waitKey="loading_key_history" />
        </div>

        <div class="flex justify-end rounded-b-lg border-t w-full p-3 bg-gray-100 absolute bottom-0 inset-x-0">
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="closeModal">{{ $t('common.close') }}</el-button>
            </span>
        </div>
    </el-dialog>
</template>
<script>
import Api from './keys.api';

export default {
    props: {
        visible: {
            type:    Boolean,
            default: false,
        },
        keyUuid: {
            type:    String,
            default: null,
        },
    },

    data() {
        return {
            noDataInformation: false,
            historyData:       [],
        };
    },

    methods: {
        async getHistory() {
            this.$wait.start('loading_key_history');
            const data = await Api.getKeyHistory(this.keyUuid);

            data.history[data.history.length - 1].holder = `${this.$t('clients.registered_by')} ${data.history[data.history.length - 1].holder}`;

            this.historyData = data.history;
            this.noDataInformation = data.history.length;
            this.$wait.end('loading_key_history');
        },

        closeModal() {
            axios.cancelAll();
            this.$emit('close');
        },

        clearData() {
            this.historyData = [];
        },

        currentHolderFormatter(holder) {
            if (holder === 'office' || holder === 'client') {
                return this.$t(`clients.at_${holder}`);
            }
            return holder;
        },
    },
};
</script>
<style scoped>
.timeline-container {
    min-height: 370px;
}
</style>
